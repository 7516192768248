import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'payment_audit_status',
    },
    {
      label: '费用类型',
      prop: 'feeType',
      propDesc: 'feeTypeDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'ap_fee_type',
    },

    {
      label: '金额(人民币)',
      prop: 'amountRmb',
      propDesc: 'amountRmbDesc',
      minWidth: 150,
      value: [],
      type: 'number',
      filterProp: ['amountRmbMinVal', 'amountRmbMaxVal'],
    },
    {
      label: '修改后金额',
      prop: 'amountRmbAfterModify', // actualWeightSum
      propDesc: 'amountRmbAfterModifyDesc',
      minWidth: 150,
      value: [],
      type: 'number',
      filterProp: ['amountRmbAfterModifyMinVal', 'amountRmbAfterModifyMaxVal'],
    },
    {
      label: '备注',
      prop: 'remark',
      minWidth: 180,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '凭据文件',
      prop: '',
      minWidth: 90,
      value: '',
      type: 'input', // 筛选类型 input 输入项
      isSort: false,
      isFilter: false,
      component: defineComponent({
        template: `
        <tableFileContent :readOnly="true"
        :fileUrlsArray="row.fileVoList"
        key-name="orderArapFileName"
        key-url="orderArapFileUrl"
        key-id="orderArapFileId"
      ></tableFileContent>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          return {};
        },
      }),
    },

    {
      label: '上条记录审核时间',
      prop: 'lastAuditTime',
      minWidth: 190,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['lastAuditTimeStartDate', 'lastAuditTimeEndDate'],
    },
    {
      label: '此条记录审核时间',
      prop: 'auditTime',
      minWidth: 190,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 120,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },

    {
      label: '审核说明',
      prop: 'auditDesc',
      minWidth: 167,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
  ];
  return { columnList, tableRef };
};
